@import "_mixins";
@import "_vars";

/* TODO: Remove Bootstrap, its utilities class conflict with tailwindcss
  eg: mb-4(margin-bottom: 1rem) in Tailwind, and margin-bottom: 1.5rem !important; in Bootstrap.
*/
@import "./bootstrap_custom";
@import "./iconfont.scss";
@import "./markdown.scss";

@import "bootstrap-select/dist/css/bootstrap-select.min";

.bootstrap-select {
  .dropdown-toggle {
    background-color: var(--form-control-background-color);
    box-shadow: var(--form-control-box-shadow);
    border: 1px solid var(--form-control-border-color);
    border-radius: 3px;
    outline: 0 !important;
  }

  @media (max-width: 480px) {
    width: 100px !important;
  }
}

input[type="file"]::file-selector-button {
  margin-left: 0;
  padding: 3px 3px;
  border-radius: 3px;
}

a {
  text-decoration: none;
}

.divider {
  color: var(--divider-color) !important;
}

/* 去掉 Bootstrap 的蓝色 outline */
* {
  outline-style: none;
}

input[type="file"]::-webkit-file-upload-button {
  color: var(--primary);
  background: var(--btn-secondary-background-color);
  -webkit-appearance: none !important;
  border: 0;
  outline: none;
  font-weight: bold;
  padding: 0;
  padding-right: 10px;
}

textarea,
#preview {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background: #f0f0f0;
    border: 3px solid #fff;
    border-radius: 9px;
  }
}

.alert {
  box-shadow: 0 0 0;
  padding: 0.65rem 1.25rem;
  border: 2px solid transparent !important;
  .btn-close {
    color: var(--primary);
    opacity: 0.7;
    padding: 1em;
  }
}
.alert-block {
  .alert-block-title {
    font-weight: bold;
    font-size: 18px;
  }
  ul {
    margin: 0;
    margin-bottom: 0;
  }
}
.alert-danger {
  color: var(--red);
  background: var(--red-light);
}
.alert-warning {
  color: var(--yellow);
  background: var(--yellow-light);
}
.alert-success {
  color: var(--green);
  background: var(--green-light);
}
.alert-info {
  color: var(--blue);
  background: var(--blue-light);
}

.rucaptcha-image {
  height: 42px;
  border-radius: 0 3px 3px 0;
}

.media {
  @extend .d-flex;

  .media-body {
    @extend .flex-grow-1;
  }
}

/* Bootstrap Theme */
body {
  @apply bg-gray-100 text-gray-800 font-sans;
  @apply dark:bg-black dark:text-gray-100 pt-[48px];
}

body {
  .container {
    @apply max-w-7xl mx-auto;
  }
}

.header {
  padding: 0;
  @apply border-solid border-l-0 border-r-0 border-t-0;
  @apply bg-white text-gray-800 fixed left-0 right-0 top-0 h-[48px] border-b border-gray-200;
  @apply dark:bg-gray-900 dark:text-gray-200 dark:border-b dark:border-gray-800;

  z-index: 1030;
  line-height: 100%;

  .navbar-toggler {
    outline: none !important;

    @apply p-2 rounded bg-transparent border-none md:hidden text-gray-600;
    @apply dark:text-gray-200;

    &:active {
      @apply bg-gray-200 dark:bg-gray-800;
    }
    &:focus {
      @apply shadow-none;
    }

    .navbar-toggler-icon {
      color: var(--navbar-text-color);
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
    }
  }

  .navbar-right {
    @apply flex items-center ml-auto space-x-6;
  }

  .navbar-nav {
    @apply bg-transparent p-0;

    .nav-link {
      padding: 15px 15px;
      @apply text-gray-800;
      @apply dark:text-gray-200;
    }
  }

  .collapse.show {
    .navbar-nav {
      @apply shadow-sm;
    }
  }

  .navbar-topic-title {
    display: none;
    z-index: 99;
  }

  &.navbar-fixed-active {
    @apply shadow-sm;
  }

  .navbar-brand {
    line-height: 100%;
    font-family: PingFang SC, Noto Sans, Roboto, Microsoft Yahei, sans-serif;
    @apply text-lg py-2 mr-6 text-gray-500 border-none;
    @apply dark:text-gray-100;

    b {
      @apply text-blue-600;
      @apply dark:text-gray-300;
    }
  }

  .main-navbar-open {
    @apply flex md:flex absolute top-12 w-52;
    @apply rounded bg-white shadow border-none text-gray-800;
    @apply dark:bg-gray-900 dark:text-gray-200 dark:shadow-lg;
  }

  #main-nav-menu {
    &.navbar-nav .nav-item a.nav-link {
      border: 0;

      padding: 16px 16px;

      @apply border-none md:border-solid border-b-2 transition-all duration-200 ease-in-out;
      @apply border-transparent text-gray-800;
      @apply dark:text-gray-300;
      font-size: 16px;
    }
    &.navbar-nav .nav-item a.nav-link:active {
      @apply border-blue-600 text-blue-600;
      @apply dark:border-gray-600 dark:text-gray-100;
    }
    &.navbar-nav .nav-item {
      a:hover,
      a.active,
      a.active:focus,
      a.active:hover {
        @apply text-blue-600 border-blue-600;
        @apply dark:border-gray-600 dark:text-gray-200;
      }
    }
  }

  .form-search {
    position: relative;
    padding: 15px 0;
    height: 18px;
    @apply text-sm hidden lg:flex items-center justify-around bg-gray-100 rounded-3xl space-x-2;
    @apply dark:bg-black dark:bg-opacity-80 dark:border dark:border-solid dark:border-gray-800;

    .fa-search {
      @apply ml-3 cursor-pointer text-gray-200;
      @apply dark:text-gray-700;
    }

    input {
      height: 18px;
      line-height: 18px;
      box-sizing: border-box;
      box-shadow: none !important;
      @apply w-28 focus:w-48 transition-all duration-300 ease-in-out;
      @apply outline-none border-none shadow-none text-sm cursor-text bg-transparent text-gray-600;
      @apply dark:text-gray-300;

      &:focus {
        @apply outline-none border-none shadow-none appearance-none;

        &::-webkit-input-placeholder {
          @apply text-gray-400;
          @apply dark:text-gray-600;
        }
      }
      &::-webkit-input-placeholder {
        @apply text-gray-300;
        @apply dark:text-gray-700;
      }
    }
  }

  .navbar-nav-svg {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
  }

  .dropdown.show {
    background: var(--navbar-dropdown-show-background-color);
  }

  .dropdown-avatar {
    line-height: 100%;
    display: inline-block;
    img.avatar-32 {
      width: 20px;
      height: 20px;
    }
  }

  .user-bar {
    @apply flex items-center justify-end min-w-[130px] gap-1;

    .nav-item {
      @apply block;
    }

    .nav-link {
      @apply flex items-center rounded p-2 hover:bg-gray-100;
      @apply dark:hover:bg-gray-800;
    }

    .dropdown-menu {
      @apply absolute;
    }
  }
}

#main {
  margin-top: 20px;
}

.sub-navbar {
  @apply bg-white shadow-sm;
  @apply dark:bg-gray-900 dark:border-t dark:bg-opacity-70 dark:border-solid dark:border-gray-900;
  @apply border-b-0 md:border-b;

  a {
    color: $blue;
  }
}

.btn {
  outline: none;
  box-shadow: var(--btn-box-shaodw);
  border-radius: var(--btn-border-radius);

  &:focus {
    box-shadow: none;
  }

  &:hover {
    transition-property: background-color;
    transition-duration: 0.7s;
    transition-timing-function: ease-out;
  }
}

.btn-secondary {
  border-color: var(--btn-secondary-border-color);
  background: var(--btn-secondary-background-color);
  color: var(--btn-secondary-text-color) !important;

  &:hover {
    background: var(--btn-secondary-hover-background-color);
    border-color: var(--btn-secondary-hover-border-color);
  }
  &:active,
  &.active {
    color: var(--btn-secondary-active-text-color);
    border-color: var(--btn-secondary-active-border-color);
    background: var(--btn-secondary-active-background-color);
  }
}

.btn-warning {
  background: var(--yellow);
  border-color: var(--yellow);
  color: #333 !important;

  &:hover {
    background: var(--yellow);
    border-color: var(--yellow);
  }
}

.btn-success {
  background: var(--green);
  border-color: var(--green);
  color: #fff !important;

  &:hover {
    background: var(--green);
    border-color: var(--green);
  }
}

.btn-danger {
  background: var(--red);
  border-color: var(--red);
  color: #fff !important;

  &:hover {
    background: var(--red);
    border-color: var(--red);
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.form-group {
  @extend .mb-3;
}

.form-actions {
  @extend .d-grid;
  @extend .gap-2;
  @extend .d-block;
  @extend .d-md-flex;

  .btn {
    min-width: 100px;
  }
  .btn-primary {
    min-width: 150px;
  }
}

.form-error {
  margin-top: 5px;
  color: #ee4343;
}

.form-text {
  display: block;
  margin-top: 5px;
  font-size: 14px;
}

.form-control {
  background-color: var(--form-control-background-color);
  border-color: var(--form-control-border-color);
  box-shadow: var(--form-control-box-shadow);
  color: var(--primary);

  @apply dark:border-gray-800 dark:bg-black;

  &:-internal-autofill-selected {
    background-color: var(--form-control-background-color) !important;
    border-color: var(--form-control-border-color) !important;
    color: var(--primary) !important;
  }

  &:focus {
    background-color: var(--form-control-background-color);
    border-color: var(--form-control-border-color);
    outline-offset: 2px;
    border-color: #2563eb;
    outline: 2px solid transparent;
    outline: var(--form-control-focus-border-color);
    color: var(--primary);
  }

  &[readonly],
  &:disabled {
    background: var(--form-control-disabled-background-color);
  }

  &::placeholder {
    @apply text-gray-400 dark:text-gray-600;
  }
}

input[type="file"] {
  background: transparent;
}

.input-group-text {
  background-color: var(--input-group-background-color);
  border-color: var(--input-group-border-color);
  box-shadow: var(--form-control-box-shadow);
  color: var(--primary);
}

.list-group-item {
  a {
    color: var(--primary);
  }
}

.nav-tabs {
  li:first-child {
    margin-left: 15px;
  }

  .nav-item {
    .nav-link {
      &:hover {
        background: transparent;
        border-color: transparent;
        color: var(--text-color);
      }

      &.active {
        color: var(--text-color1);
        background: var(--card-background-color);
        border: 1px solid var(--card-border-color);
      }
    }
  }
}

.nav-pills {
  .nav-link {
    &.active {
      background: var(--nav-pills-active-background-color);
      color: var(--nav-pills-active-text-color);
    }
  }
}

.close {
  font-size: 1.2rem;
  font-weight: normal;
}

.dropdown-menu {
  line-height: 1.8em;
  border-radius: 0.375rem;
  background-color: var(--dropdown-menu-background-color);
  border-color: var(--dropdown-menu-border-color);
  box-shadow: var(--dropdown-menu-box-shadow);
  color: var(--dropdown-menu-text-color);
  transform-origin: top right;

  .dropdown-item {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: var(--dropdown-menu-text-color);

    &:hover {
      color: var(--dropdown-item-active-text-color);
      background-color: var(--dropdown-item-active-background-color);
    }
  }

  .dropdown-item.active {
    font-weight: 500;
    color: var(--dropdown-item-active-text-color);
    background-color: var(--dropdown-item-active-background-color);
  }

  .dropdown-divider {
    margin: 4px 0;
    border-color: var(--dropdown-menu-divider-color);
  }
}

.pagination {
  margin: 0;
  & > li:first-child > a,
  & > li:first-child > span {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  & > li:last-child > a,
  & > li:last-child > span {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  li > a {
    color: var(--primary);
  }

  .page-link {
    @extend .btn-secondary;
  }

  li > a,
  .disabled > a,
  li > span {
    outline: none;
    border-color: #eee;
  }

  .disabled > .page-link {
    background-color: #f0f0f0;
    color: #aaa !important;
  }

  li > a:hover {
    color: var(--text-color1);
    background: var(--gray);
  }

  li.active > a,
  li.active > a:hover {
    color: #fff !important;
    background-color: var(--blue);
    border-color: var(--blue) !important;
  }
}

.breadcrumb {
  background: transparent;
}

.pager {
  margin: 0px;
  .info {
    line-height: 32px;
    color: #ccc;
    samp {
      color: #999;
    }
  }
  li > a,
  li > span {
    color: #666;
    border-radius: 3px;
    border: 0px;
    background: transparent;
    &:hover {
      background: #fff;
    }
  }
  li.disabled > a,
  li.disabled > span {
    color: #ddd;
    background: transparent;
    &:hover {
      color: #ddd;
      background: transparent;
    }
  }
}
abbr {
  text-decoration: none;
  border-bottom: 0px;
  cursor: pointer;
}
abbr[title] {
  border: 0 !important;
  text-decoration: none;
  cursor: text;
}
a abbr[title] {
  cursor: pointer;
}

kbd {
  background-color: var(--gray);
  color: var(--secondary);
  border-radius: 2px;
  border-color: var(--gray);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.05);
}

.table {
  @apply min-w-full divide-y divide-gray-300 table-auto;

  th {
    @apply py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-500;
  }

  tr:not(first-child) {
    td {
      @apply border-t border-gray-200 dark:border-gray-800;
    }
  }
  td {
    @apply hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell;
  }
}

.card {
  @apply border-none bg-white shadow-sm sm:rounded-lg;
  @apply bg-white;
  @apply dark:bg-gray-900 dark:border dark:border-solid dark:border-gray-800;

  .card {
    border: 1px solid var(--card-inset-border-color);
  }

  .card-header {
    @apply text-gray-800 dark:text-gray-100 text-lg mb-6 leading-4;
    padding: 15px 25px 0 25px;
    border: 0px;
    border: 0;
    font-size: 18px;
    background: transparent !important;
  }

  .card-footer {
    border: 0;
    padding-top: 0;
    padding: 0 25px 25px 25px;
    background: transparent !important;
  }

  .card-title {
    @apply flex items-center text-gray-800 dark:text-gray-100 text-lg mb-6 leading-4;

    .card-subtitle {
      margin-top: 20px;
      font-size: 14px;
    }

    .counter {
      margin-left: 5px;
      color: var(--card-title-sencondary-color);
      font-size: 14px;
    }

    .actions {
      font-size: 14px;
      margin-left: 25px;
    }
  }

  .card-body {
    padding: 25px;
    @apply text-gray-800 dark:text-gray-100;
  }

  .list-group {
    padding-top: 15px;
    border: 0;

    .list-group-item {
      padding: 15px 25px;
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      color: var(--text-color1);
    }
  }
}

@media (min-width: 768px) {
  .card.card-lg {
    .card-body {
      padding: 30px;
    }
  }
}

.nav-link {
  color: var(--navbar-text-color);
  &:hover {
    color: var(--primary);
  }
}

.nav-stacked.nav-pills > li > a {
  border-radius: 0px;

  i.fa {
    width: 20px;
  }
}

/* Modal */
.modal {
  top: 120px;
  .modal-dialog {
    max-width: 640px;
  }

  .modal-content {
    border: 0px;
    background-color: var(--modal-background-color);
    box-shadow: 0 2px 15px var(--modal-shadow-color) !important;

    @apply dark:bg-gray-900 dark:border dark:border-solid dark:border-gray-800 dark:shadow-2xl;

    .card {
      background-color: var(--modal-background-color);
    }

    .close {
      color: var(--secondary);
      text-shadow: none;
    }

    p {
      margin-bottom: 5px;
    }
  }
  .modal-header {
    border: 0px;
    .close {
      font-size: 1.9rem;
      font-weight: normal;
    }
    .modal-title {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .modal-footer {
    text-align: left;
    font-size: 16px;
    border: 0;
    justify-content: flex-start;
  }

  @media (min-width: 768px) {
    .modal-dialog {
      width: 768px;
    }
  }
}

.modal-backdrop {
  opacity: 0 !important;
}

.list-group {
  border-top: 0 !important;
  .list-group-item {
    background-color: transparent;
    border-color: var(--list-group-item-border-color);
  }
}

.fa-spin {
  -webkit-animation: fa-spin 0.8s infinite linear;
  animation: fa-spin 0.8s infinite linear;
}

/* App Style */
.opts {
  color: var(--opts-text-color);
  a:not(.dropdown-item) {
    display: inline-block;
    border-radius: var(--btn-border-radius);
  }
  a:link:not(.dropdown-item),
  a:visited:not(.dropdown-item) {
    min-width: 28px;
    text-align: center;
    color: var(--opts-link-color);
    line-height: 100%;
    padding: 5px 8px;
    text-decoration: none;
  }

  .dropdown.show a[data-bs-toggle="dropdown"],
  a:hover:not(.dropdown-item) {
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    background: var(--opts-link-hover-background-color);
    text-decoration: none;
  }

  a.active:not(.dropdown-item) {
    .fa {
      color: var(--opts-active-icon-color);
      &.fa-heart {
        color: var(--red);
      }
    }
    &.animate {
      .fa {
        @extend .animated;
        @extend .bounceIn;
      }
    }
  }
}

.turbolinks-progress-bar {
  background-color: var(--blue) !important;
  height: 2px !important;
}

.avatar {
  .uface,
  .media-object {
    border-radius: 120px;
  }
}
.avatar-16 {
  width: 16px;
  height: 16px;
  border-radius: 120px;
}
.avatar-32 {
  width: 32px;
  height: 32px;
  border-radius: 120px;
}
.avatar-48 {
  width: 40px;
  height: 40px;
  border-radius: 120px;
}
.avatar-96 {
  width: 96px;
  height: 96px;
  border-radius: 120px;
}
@media (max-width: 480px) {
  .avatar-48 {
    width: 28px;
    height: 28px;
  }
}

.uname {
  color: #666;
}
img.emoji {
  width: 20px;
  height: 20px;
}
.node-name {
  background: #f0f0f0;
  padding: 1px 3px;
  color: #777;
  margin-right: 5px;
  &:hover {
    color: #555;
    text-decoration: none;
    background: #e0e0e0;
  }
}
.fa.awesome {
  font-size: 13px;
  color: $red;
}
.notification-count {
  .count {
    margin-left: 4px;
    display: none;
    font-size: 12px;
  }
  .new {
    color: $red !important;
    .count {
      display: inline;
      line-height: 100%;
    }
  }
}
.deleted {
  text-decoration: line-through;
  color: var(--default);
}
.no-result {
  color: #aaa;
  padding-bottom: 20px;
  text-align: center;
}

/* User role */
.badge-role {
  @extend .badge;
  font-size: 12px;
  font-weight: normal;
  font-family: sans-serif;
  background: #564cf5;
  color: #fff;
}

.embed-responsive {
  display: block;

  @extend .ratio;

  &-16by9 {
    @extend .ratio-16x9;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.setting-menu {
  padding-right: 0;
  padding-left: 0px;
}

@media (min-width: 768px) {
  .setting-menu {
    padding-left: 15px;
  }
}

.editor-toolbar {
  margin-bottom: 5px;

  .toolbar-buttons {
    a.nav-link {
      @extend .btn-secondary;

      font-size: 14px;
      line-height: 14px;
      display: inline-block;
      border-radius: 3px;
      padding: 6px;
      margin: 0;
    }

    .upload-button {
      &.active {
        .upload-icon {
          display: none;
        }
        .loading {
          display: inline-block;
        }
      }

      .loading {
        display: none;
      }
    }
  }

  .reply-to {
    margin-top: 10px;
    font-size: 14px;
    line-height: 14px;
    .fa {
      margin-right: 8px;
    }
    .close {
      font-size: 14px;
      margin-left: 5px;
    }
  }
}

@media (max-width: 480px) {
  body {
    font-size: 14px;
    line-height: 100%;
  }

  .card {
    border-radius: 0;
    border-left: 0px;
    border-right: 0px;
  }

  #main {
    margin-top: -1px;
  }

  .row {
    margin: 0;
    .col-lg-9,
    .col-lg-8,
    .col-lg-6,
    .col-lg-5,
    .col-lg-4,
    .col-lg-3 {
      padding: 0;
    }
  }
  .sidebar {
    display: none;
  }
  .hidden-mobile {
    display: none !important;
  }

  .topics {
    .topic {
      .node {
        display: none;
      }
      .count {
        font-size: 12px;
        width: 38px !important;
      }
    }
  }

  .pagination {
    display: block;
    li {
      display: none;
    }
    li.prev,
    li.next {
      float: left;
      display: block;
      a {
        border-radius: 20px !important;
      }
    }
    li.next {
      float: right;
    }
  }
}
